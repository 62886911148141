import React from 'react';
import './App.css';
import MountRoukeLogo from './MountRoukeLogo.svg';
import firebase from 'firebase';

firebase.initializeApp({
  apiKey: "AIzaSyAYQHMWVSUn8a1sKewNYqxN1qRIuA2c46U",
  authDomain: "pixlinglab.firebaseapp.com",
  databaseURL: "https://pixlinglab.firebaseio.com",
  projectId: "pixlinglab",
  storageBucket: "gs://pixlingworld",
  messagingSenderId: "87823588661"
});

function NewsletterSignup() {
  const [email, setEmail] = React.useState('');
  return <div>
    <input type="text" value={email} onChange={e => setEmail(e.target.value)} placeholder="your@email.com"/>
    <button
      onClick={async () => {
        await firebase.functions().httpsCallable('subscribeToNewsletter')({ email });
        setEmail('');
      }}
      disabled={!email}>
        Subscribe to newsletter
    </button>
  </div>
}

function App() {
  return <div className="App">
    <iframe className="Trailer" width="560" height="315" src="https://www.youtube.com/embed/-Lqb8cce5tk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen={true} />

    <a className="SteamLink Button" href="https://store.steampowered.com/app/1102370/Dr_Derks_Mutant_Battlegrounds">
      Get the game on Steam
    </a>
    <a className="GymLink Button" href="https://gym.derkgame.com">
      Derk's Gym: Python RL Environment
    </a>
    <div className="Description Section">
      <h3>Train and battle AI creatures in a wonky western-themed MOBA!</h3>

      <p>Welcome to Dr. Derk's Mutant Battlegrounds!</p>

      <p>This is a game where you train Derklings, a creature with a neural network brain, to fight for you! The game takes place in a wonky western-themed MOBA setting, and as you battle other players you earn upgrades and items for your Derklings. The challenge is to figure out the best loadout and training strategy for your Derklings!</p>

      <p>There are tons of strategies to explore, as the Derklings can be taught to do an endless number of things (thanks to the neural networks). How about teaching one of them to heal a tanky Derkling? Or make one of them dash through the opponents and go straight for the opponents statue? Or an all-out DPS team? Or try to make them trick the opponents? There's a lot to explore.</p>

      <p>The game employs "real" artificial intelligence. The Derklings have neural networks with over 3,000 parameters. They can "see" over 30 things and take decisions on their own. The technology is similar (but simplified) to what researchers at OpenAI used to beat top DOTA players two years ago.</p>

      <p>Some things to look forward to:</p>

      <ul>
      <li> A dead simple way to get started training your own neural networks creatures. Just load them up, set some goals (bounties) and hit train!</li>
      <li> Some technical details; the agents employ a RNN with over 3,000 parameters and 32 memory slots, and are trained with GA (Genetic Algorithm). Plenty enough for them to come up with all kinds of strange, funny and clever solutions!</li>
      <li> A bunch of fun upgrades, such as a horn, a shell, huge guns, bubblegum and much more. They all change what the Derklings can do and what they might learn.</li>
      <li> A global ladder where you can battle other players and earn new items.</li>
      <li> Neural network visualizations for those who wish to dig deeper into the brains of the Derklings and fully understand their behaviors.</li>
      <li> A community of people fascinated by AI and neural networks.</li>
      </ul>

      <p>Dr. Derk wishes you and your team the best of luck!</p>
    </div>
    <div className="Section">
      <NewsletterSignup />
      <p>
        <a href="https://www.youtube.com/channel/UC8ZuJCgnZMCmz3WZoEM_czA">YouTube</a> •&nbsp;
        <a href="http://twitter.com/DrDerkGame">Twitter</a> •&nbsp;
        <a href="https://discord.gg/ZBRwc5b">Discord</a> •&nbsp;
        <a href="https://www.patreon.com/fredriknoren">Patreon</a> •&nbsp;
        <a href="https://app.derkgame.com">Login</a>
      </p>
    </div>
    <a href="http://mountrouke.com"><img src={MountRoukeLogo} alt="Mount Rouke Studios" className="MountRoukeLogo" /></a>
  </div>;
}

export default App;
